.lb {
	border: 1px solid black;
}

.lb1 {
	border-top: 1px solid black;
	border-left: 1px solid black;
	border-right: 1px solid black;
}
.lb2 {
	border-left: 1px solid black;
	border-right: 1px solid black;
	border-bottom: 1px solid black;
}
.lb3 {
	/* border: 1px solid black;	 */
	background-color: #FFC000 ;
	border-left: 1px solid black;
	border-right: 1px solid black;
}
.lb4 {
	border-right: 1px dotted black;
}
.lb_top {
	border-top: 1px solid black;
	border-left: 1px solid black;
	border-right: 1px solid black;
	border-bottom: 1px dotted lightgray;
}
.lb_mid {
	border-top: 1px dotted lightgray;
	border-left: 1px solid black;
	border-right: 1px solid black;
	border-bottom: 1px dotted lightgray;
}

.lb_bottom {
	border-top: 1px dotted lightgray;
	border-left: 1px solid black;
	border-right: 1px solid black;
	border-bottom: 1px solid black;
	margin-bottom: 6px;
}


.rb_top {
	border-top: 1px solid black;
	border-left: 1px solid black;
	border-right: 1px solid black;
	border-bottom: 1px dotted lightgray;
}
.rb_mid {
	border-top: 1px dotted lightgray;
	border-left: 1px solid black;
	border-right: 1px solid black;
	border-bottom: 1px dotted lightgray;
}

.rb_bottom {
	border-top: 1px dotted lightgray;
	border-left: 1px solid black;
	border-right: 1px solid black;
	border-bottom: 1px solid black;
	margin-bottom: 6px;
}

@media all {
    .page-break {
      display: none;
    }
  }
  
  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  
  @media print {
    .page-break {
      margin-top: 1rem;
      display: block;
      /* page-break-before: auto; */
	  page-break-inside: avoid;
    }
  }
  
  @page {
    size: auto;
    margin: 20mm;
  }